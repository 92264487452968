<template>
  <div
    v-if="placements?.footer?.html && isPdp"
    class="vp">    
    <Sheet
      :placement="placements.footer" />
  </div>
  <footer class="footer__wrapper">
    <Newsletter />
    <Logos />
    <Links />
    <Webs />
    <Copyright />
  </footer>
</template>
<script>
  import Newsletter from './Newsletter.vue';
  import Logos from './Logos.vue';
  import Links from './Links.vue';
  import Webs from './Webs.vue';
  import Copyright from './Copyright.vue';
  import { mapGetter } from 'CommonUtils/store/state.js';
  import Sheet from 'CommonComponents/Sheets/Render.vue';  

  export default {
    name: 'FooterComponent',
    components: {
      Sheet,
      Newsletter,
      Logos,
      Links,
      Webs,
      Copyright
    },
    setup() {
      const placements = mapGetter('page/getPlacements');
      const isPdp      = mapGetter('page/getIsPdp');
      return {
        placements,
        isPdp,
      }
    },
  };
</script>
