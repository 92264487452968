<template>
  <div class="CartItemRelatedServices">
    <p class="CartItemRelatedServices__title">
      {{ $t('cart.related_services') }}
    </p>
    <CartItemRelatedService
      v-if="installationService.items.length"
      v-bind="installationService" />
    <CartItemRelatedService
      v-if="insuranceService.items[0]"
      :is-insurance="true"
      :style="{'margin-top':installationService.items[0] ? '16px':'0'}"
      v-bind="insuranceService" />
  </div>
</template>

<script setup>
  import { computed } from 'vue'
  import useCartActionsLogic from 'CommonComposables/useCartActionsLogic';
  import { useI18n } from 'vue-i18n';  
  import CartItemRelatedService from './CartItemRelatedService.vue';

  
  const { t } = useI18n();
  const { getFFItemInstallationServices, getOMSItemInstallationServices, getFFInsuranceItemServices, getOMInsuranceItemServices } = useCartActionsLogic();

  const props = defineProps({
    item: { type: Object, required: true }
  });

  const installationService = computed(() => {
    const items = [];

    if (props.item.is_recycling_service_required) items.push(getInstallationFreeServiceItem('product.related_services.disposal'));
    if (props.item.is_installation_service_required) items.push(getInstallationFreeServiceItem('product.related_services.startup'));
    if (props.item.is_fridge_door_change_required) items.push(getInstallationFreeServiceItem('product.related_services.door_service'));

    items.push(...getFFItemInstallationServices(props.item));
    items.push(...getOMSItemInstallationServices(props.item));
    
    return {
      iconClass: "i-wrench",
      title: "Servicios asociados",
      items
    }
  })

  const getInstallationFreeServiceItem = title => {
    return {
      title: t(title),
      units: props.item.units,
      price: 0
    }
  }
  const insuranceService = computed(()=>{
    const insurance = getFFInsuranceItemServices(props.item) || getOMInsuranceItemServices(props.item);
    return {
      iconClass: 'i-insurance',
      title: insurance?.title ?? '',
      items:[insurance],
      parentItem:props.item,
      isInsurance:true
    }
  });

</script>

<style lang="less" scoped>
  @import 'LessFiles/fontsSizes.less';

  .CartItemRelatedServices {
    padding-bottom: 1.5em;
    text-align: left;

    &__title {
      font-size: @font-size-s;
      padding: 1em 1em 1em 0;
      color: #000000;
      font-weight: 400;
    }
  }
</style>